<template>
  <div class="wrapper">

    <Header />

    <div class="login">
      <div class="login-content">

        <h1 class="heading-r">Log in</h1>

        <div class="login-error" v-if="authError != ''">{{ authError }}</div>

        <form v-on:submit.prevent="logInUser">
          <input type="text" id="username" placeholder="Username" autocomplete="off" required v-model="username">
          <input type="password" id="password" placeholder="Password" autocomplete="off" required v-model="password">
          <button class="button button-primary" type="submit">Log in</button>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Header from '@/components/Header'

export default {
  name: 'Login',
  components: {
    Header
  },
  data () {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    ...mapGetters(['authError'])
  },
  methods: {
    logInUser: function () {
      const self = this
      this.$store.dispatch('logInUser', {
        username: this.username,
        password: this.password
      }).then(function () {
        self.$router.push('admin')
      })
    }
  },
  watch: {
    username: function () {
      if (this.$store.state.authError !== '') {
        this.$store.commit('AUTH_ERROR', '')
      }
    },
    password: function () {
      if (this.$store.state.authError !== '') {
        this.$store.commit('AUTH_ERROR', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: calc(100vh - 86px);
  display: flex;
  justify-content: center;
  align-items: center;
  &-content {
    width: 100%;
    max-width: 300px;
    text-align: center;
    h1 {
      margin-bottom: 32px;
    }
    form {
      input {
        margin-bottom: 16px;
      }
      button {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
  &-error {
    background: $red-50;
    color: white;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 16px;
  }
}
</style>
